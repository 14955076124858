<div class="d-flex flex-column flex-root app-root w-100">
  <!--begin::Page-->
  <div class="app-page flex-column flex-column-fluid ">

    <!--
    <bws-header class="d-block d-lg-none"></bws-header>
    -->

    <!--begin::Wrapper-->
    <div class="app-wrapper flex-column flex-row-fluid">

      <bws-sidebar [navLinks]="navLinks">
        <ng-container logo>
          <img alt="Logo" src="/assets/images/logos/shippinglabel-logo_center-light.svg" class="w-175px">
        </ng-container>
      </bws-sidebar>

      <!--begin::Main-->
      <div class="app-main flex-column flex-row-fluid">

        <div class="d-flex flex-column flex-column-fluid">
          <div class="flex-column-fluid">
            <router-outlet></router-outlet>
          </div>
        </div>

        <app-footer class="bg-white" />

        <!--<bws-footer></bws-footer>-->
      </div>

    </div>
    <!--end::Wrapper-->

  </div>
  <!--end::Page-->
</div>
