import { ErrorHandler, NgModule } from '@angular/core';
import { LayoutsModule } from './layouts/layouts.module';
import { BwsCoreModule } from '@bws-dev/ngx-bws-shared';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { UniversalRelativeInterceptor } from '@core/services/universal-relative.interceptor';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { provideErrorHandler } from '@core/sentry-error-handler';


@NgModule({
  declarations: [],
  imports: [
    LayoutsModule,
    HttpClientModule,
    BwsCoreModule.forRoot(),
  ],
  exports: [
  ],
  providers: [
    provideClientHydration(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniversalRelativeInterceptor,
      multi: true
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        config: {
          ignoreUnescapedHTML: true,
        },
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'), // Optional, only if you want the line numbers
        languages: {
          html: () => import('highlight.js/lib/languages/xml'),
          css: () => import('highlight.js/lib/languages/css'),
        },
        // themePath: 'github-dark.css' // Optional, and useful if you want to change the theme dynamically
      }
    },
    { provide: ErrorHandler, useFactory: provideErrorHandler },
  ],
})
export class CoreModule { }
