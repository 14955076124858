import { Component, OnInit } from '@angular/core';
import { BwsNavLink, PlatformService } from '@bws-dev/ngx-bws-shared';
import { CookieConsentService } from '@core/services/cookie-consent.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  constructor(private platformService: PlatformService,
              private cookieConsent: CookieConsentService) {}

  public navLinks: BwsNavLink[] = [
    { title: 'NAV-LINKS.HOME', icon: 'home', route: '/home' },
    { title: 'NAV-LINKS.FEATURES', icon: 'archive-star', route: '/features' },
    { title: 'NAV-LINKS.CARRIER', icon: 'truck-fast', route: '/download' },
    { title: 'NAV-LINKS.SHOP-SYSTEMS', icon: 'store-search', route: '/download' },
    { title: 'NAV-LINKS.FAQ', icon: 'frequently-asked-questions', route: '/faq' },
    { title: 'NAV-LINKS.API-DOCS', icon: 'book-open-blank-variant', route: '/faq' },
    { title: 'NAV-LINKS.PRICING', icon: 'cash-multiple', route: '/pricing' },
    { title: 'NAV-LINKS.CONTACT', icon: 'email', route: '/contact' },
  ];

  ngOnInit() {
    if (this.platformService.isBrowser) {
      this.cookieConsent.initCookieConsent();
    }
  }

}
