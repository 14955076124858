import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { LocalizeParser, LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  private altHrefLinks: HTMLLinkElement[] = [];

  constructor(@Inject(DOCUMENT) private document: Document,
              private translate: TranslateService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private titleService: Title,
              private metaService: Meta,
              private localize: LocalizeRouterService,
              public parser: LocalizeParser) { }

  public init(){


    // Update the language in the lang attribute of the html element.
    this.translate.onLangChange
      .pipe(startWith(true))
      .subscribe(() => {
        this.document.documentElement.lang = this.translate.currentLang;
      });

    // Update meta tags and title
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(startWith(true))
      .subscribe(() => {

        this.updateAltLangTags();

        const rt = this.getChild(this.activatedRoute)
        rt.data.subscribe((data: any) => {
          if (data.title) {
            this.titleService.setTitle(this.translate.instant(data.title));
            this.metaService.updateTag({ property: 'og:title', content: this.translate.instant(data.title) })
          } else {
            this.titleService.setTitle('Shippinglabel');
            this.metaService.removeTag("property='og:title'")
          }
          if (data.description) {
            this.metaService.updateTag({ name: 'description', content: this.translate.instant(data.description) })
            this.metaService.updateTag({ name: 'og:description', content: this.translate.instant(data.description) })
          } else {
            this.metaService.removeTag("name='description'")
            this.metaService.removeTag("property='og:description'")
          }
          if (data.keywords) {
            this.metaService.updateTag({ name: 'keywords', content: this.translate.instant(data.keywords) })
          } else {
            this.metaService.removeTag("name='keywords'")
          }

          this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          this.metaService.updateTag({ property: 'og:url', content: this.router.url })

          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }

          this.metaService.removeTag("property='og:site_name'")
          this.metaService.removeTag("property='og:type'")
        })

      })
  }

  private getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private updateAltLangTags() {

    let validPath = true;
    const pathSegments: string[] = [];
    let path = '';

    const child = (activatedRoute: ActivatedRoute): any  => {
      if (activatedRoute.firstChild) {
        if (activatedRoute.firstChild.snapshot.url?.length) {
          if (activatedRoute.firstChild.snapshot.data['skipRouteLocalization']) {
            validPath = false;
          } else {
            const lPath = activatedRoute.firstChild.snapshot.data['localizeRouter']?.path;
            if (lPath) {
              pathSegments.push(lPath);
              path += '/' + lPath;
            }
          }
        }
        child(activatedRoute.firstChild);
      }
    }

    child(this.activatedRoute);

    this.altHrefLinks.forEach(le => {
      le.parentNode?.removeChild(le);
    });
    this.altHrefLinks = [];


    if (!validPath) {
      return;
    }

    const alt: {language: string, link: string}[] = [];

    this.localize.parser.locales.forEach(locale => {
      const rt = pathSegments.map(s => this.translate.translations[locale]?.[this.localize.parser.getPrefix().slice(0, -1)]?.[s]).join('/');
      alt.push({language: locale, link: `/${locale}/` + rt});
    });

    alt.forEach(a => {
      const linkElt = this.document.createElement('link');
      linkElt.setAttribute( 'rel', 'alternate');
      linkElt.setAttribute( 'hreflang', a.language);
      linkElt.setAttribute( 'href', environment.baseUrl + a.link);
      this.altHrefLinks.push(linkElt);
      this.document.head.appendChild(linkElt);
    });

  }

}
