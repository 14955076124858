import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@bws-dev/ngx-bws-shared';

declare const initCookieConsent: any;

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {

  public cc: any;

  constructor(private translate: TranslateService,
              private ps: PlatformService) {
  }

  public initCookieConsent() {

    if (!this.ps.isBrowser) {
      return;
    }

    this.cc = initCookieConsent();

    this.cc.run({
      revision: 1,
      current_lang: this.translate.currentLang,
      autoclear_cookies: true,                   // default: false
      page_scripts: true,                        // default: false
      force_consent: false,                   // default: false
      languages: {
        'en': {
          consent_modal: {
            title: 'We use cookies!',
            description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking ' +
              'cookies to understand how you interact with it. The latter will be set only after ' +
              'consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
            primary_btn: {
              text: 'Accept all',
              role: 'accept_all'
            },
/*
            secondary_btn: {
              text: 'Manually define',
              role: 'settings'
            }
*/
          },
          settings_modal: {
            title: 'Cookie preferences',
            save_settings_btn: 'Save settings',
            accept_all_btn: 'Accept all',
            reject_all_btn: 'Reject all',
            close_btn_label: 'Close',
            cookie_table_headers: [
              {col1: 'Name'},
              {col2: 'Domain'},
              {col3: 'Expiration'},
              {col4: 'Description'}
            ],
            blocks: [
              {
                title: 'Cookie usage 📢',
                description: 'We use cookies to ensure the basic functionalities of the website and to ' +
                  'enhance your online experience. You can choose for each category to opt-in/out ' +
                  'whenever you want. For more details relative to cookies and other sensitive data, ' +
                  'please read the full <a href="/en/legal/privacy" class="cc-link">privacy policy</a>.'
              },
              {
                title: 'Strictly necessary cookies',
                description: 'These cookies are essential for the proper functioning of our ' +
                  'website. Without these cookies, the website would not work properly',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true
                }
              },
              {
                title: 'Google Analytics cookies',
                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    col1: '^_ga',
                    col2: 'google.com',
                    col3: '2 years',
                    col4: '',
                    col5: 'Permanent cookie',
                    is_regex: true
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '2 years',
                    col4: '',
                    col5: 'Permanent cookie'
                  }
                ]
              },
              {
                title: 'More information',
                description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/en/contact">contact us</a>.',
              }
            ]
          }
        },

        'de': {
          consent_modal: {
            title: 'Privatsphäre-Einstellungen',
            description: 'Diese Website verwendet essentielle Cookies, um ihren ordnungsgemäßen Betrieb ' +
              'zu gewährleisten und Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. ' +
              'Letztere werden nur nach Zustimmung gesetzt. <button type="button" data-cc="c-settings" class="cc-link">Privatsphäre-Einstellungen</button>',
            primary_btn: {
              text: 'Alle akzeptieren',
              role: 'accept_all'
            },
/*
            secondary_btn: {
              text: 'Einstellungen',
              role: 'settings'
            }
*/
          },
          settings_modal: {
            title: 'Cookie Einstellungen',
            save_settings_btn: 'Einstellungen speichern',
            accept_all_btn: 'Alle akzeptieren',
            reject_all_btn: 'Alle ablehnen',
            close_btn_label: 'Schließen',
            cookie_table_headers: [
              {col1: 'Name'},
              {col2: 'Domain'},
              {col3: 'Ablauf'},
              {col4: 'Beschreibung'}
            ],
            blocks: [
              {
                title: 'Verwendung von Cookies 📢',
                description: 'Wir verwenden Cookies, um die grundlegenden Funktionen der Website ' +
                  'zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für ' +
                  'jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere ' +
                  'Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die ' +
                  'vollständige <a href="/de/rechtliches/datenschutz" class="cc-link">Datenschutzerklärung</a>.'
              },
              {
                title: 'Technisch notwendig',
                description: 'Diese Technologien sind erforderlich, um die Kernfunktionalität der ' +
                  'Website zu aktivieren',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true
                }
              },
              {
                title: 'Google Analytics Cookies',
                description: 'Diese Cookies sammeln Informationen darüber, wie Sie die Website nutzen, welche Seiten Sie besucht und welche Links Sie angeklickt haben. Alle Daten sind anonymisiert und können nicht dazu verwendet werden, Sie zu identifizieren.',
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    col1: '^_ga',
                    col2: 'google.com',
                    col3: '2 years',
                    col4: '',
                    col5: 'Permanent cookie',
                    is_regex: true
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '2 years',
                    col4: '',
                    col5: 'Permanent cookie'
                  }
                ]
              },
              {
                title: 'Weitere Informationen',
                description: 'Bei Fragen zu unserer Politik in Bezug auf Cookies und Ihre Wahlmöglichkeiten <a class="cc-link" href="/de/kontakt">kontaktieren Sie uns</a>.',
              }
            ]
          }
        }


      }
    });
  }



}
