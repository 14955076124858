export const de = {
  lang: 'de',
  data: {
    'ROUTES': {
      'home': 'home',
      'features': 'funktionen',
      'pricing': 'preise',
      'faq': 'faq',
      'help': 'hilfe',
      'forum': 'forum',
      'blog': 'blog',
      'member': 'member',
      'login': 'login',
      'dashboard': 'dashboard',
      'support': 'support',
      'tickets': 'tickets',
      'register': 'registrieren',
      'complete': 'abschluss',
      'download': 'download',
      'contact': 'kontakt',
      'profile': 'profil',
      'general': 'allgemein',
      'address': 'adresse',
      'company': 'firma',
      'other': 'sonstiges',
      'subscription': 'tarif',
      'manage': 'verwalten',
      'accounts': 'accounts',
      'templates': 'vorlagen',
      'apps': 'apps',
      'legal': 'rechtliches',
      'terms': 'agb',
      'privacy': 'datenschutz',
      'imprint': 'impressum',
      'error': 'fehler',
      'item-management': 'artikelverwaltung',
      'import-export': 'import-export',
      'synchronization': 'synchronisation',
      'orders': 'bestellungen',
      'messages': 'nachrichten',
      'marketing': 'marketing',
      'extra': 'extras',
    },
    'NAV-LINKS': {
      'HOME': 'Startseite',
      'FEATURES': 'Funktionen',
      'CARRIER': 'Versanddienstleister',
      'SHOP-SYSTEMS': 'Shopsysteme',
      'PRICING': 'Preise',
      'FAQ': 'FAQ',
      'API-DOCS': 'API-Dokumentation',
      'HELP-PAGES': 'Hilfeseiten',
      'FORUMS': 'Forum',
      'BLOG': 'Blog',
      'CONTACT': 'Kontakt',
      'LOGIN': 'Anmelden',
      'REGISTER': 'Registrieren',
      'IMPRINT': 'Impressum',
      'PRIVACY': 'Datenschutz',
      'TERMS': 'AGB',
      'LOGOUT': 'Abmelden',
    },
    'GLOBAL': {
      'ERROR': 'Fehler',
      'ERROR-GENERAL': 'Etwas ist schief gelaufen. Bitte probieren Sie es später erneut oder kontaktieren Sie unseren Support.',
      'SESSION-EXPIRED': 'Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an',
      'CANCEL': 'Abbrechen',
      'TRY-AGAIN': 'Erneut versuchen',
      'PLEASE-WAIT': 'Bitte warten',
      'ARE-YOU-SURE': 'Sind Sie sicher?',
      'SUCCESS': 'Erfolg',
      'DELETE': 'Löschen',
      'WARNING': 'Warnung',
      'INFO': 'Info',
      'CHARS-LEFT': 'Zeichen verbleibend',
      'CLOSE': 'Schließen',
      'CLOSE-WINDOW': 'Fenster schließen',
      'COPY-TO-CLIPBOARD': 'In Zwischenablage kopieren',
      'YES': 'Ja',
      'NO': 'Nein',
      'CONFIRM': 'Bestätigen',
      'SELECT': 'Auswählen',
      'ACTIVE': 'Aktiv',
      'INACTIVE': 'Inaktiv',
      'DEFAULT': 'Standard',
      'SAVE-CHANGES': 'Änderungen speichern',
      'FOOTER': {
        'IMPRINT': 'Impressum',
        'PRIVACY': 'Datenschutz',
        'TERMS': 'AGB',
      },
      'UPDATE-AVAILABLE': {
        'HEAD': 'Update verfügbar!',
        'BODY': 'Eine neue Version ist verfügbar. Möchten Sie die Seite jetzt neu laden? Bitte beachten Sie: ' +
          'Nicht gespeicherte Änderungen gehen verloren.',
        'LATER': 'Später',
        'RELOAD': 'Seite neu laden',
      },
      'WEBSOCKET': {
        'TICKET-REPLY': 'Sie haben eine Antwort auf Ihr Support-Ticket erhalten.'
      },
      'PAYMENT': {
        'SUBTOTAL': 'Zwischensumme ohne MwSt.',
        'VAT': '{{VATPERCENT}}% MwSt. von',
        'TOTAL': 'Summe',
        'AMOUNT-TO-PAY': 'Zu zahlender Betrag',
        'CB-TERMS': 'Ich habe die AGB gelesen und bin mit den Bedingungen einverstanden',
        'OPEN-NEW-WINDOW': 'In neuem Fenster öffnen',
        'CB-CANCELLATION': 'Ich verlange ausdrücklich und stimme gleichzeitig zu, dass Sie mit der in Auftrag gegebenen Dienstleistung ' +
          'vor Ablauf der Widerrufsfrist beginnen. Ich weiß, dass mein Widerrufsrecht bei vollständiger Erfüllung des Vertrages erlischt',
        'CONTINUE-PAYPAL': 'Weiter zu PayPal',
      },
      'LOGIN-MODAL': {
        'HEAD': 'Bitte geben Sie Ihre Shippinglabel Zugangsdaten ein um sich anzumelden:',
        'E-MAIL': 'E-Mail',
        'E-MAIL-PLACEHOLDER': 'E-Mail Adresse eingeben',
        'PASSWORD': 'Passwort',
        'PASSWORD-PLACEHOLDER': 'Passwort eingeben',
        'LOGIN': 'Anmelden',
        'LOGIN-SUCCESS': 'Anmeldung erfolgreich',
      },
      'COMMUNITY-PUSH': {
        'NEW-POST': 'Neuer Beitrag von {{user}}',
        'NEW-THREAD': 'Neues Thema von {{user}}: {{threadName}}',
      },
      'LOGOUT-SUCCESS': 'Sie haben sich erfolgreich abgemeldet',
      'CTA-REGISTER': {
        'CTA-HEAD': 'Erstellen Sie jetzt Ihren kostenlosen Account!',
        'CTA-BODY': 'Anmeldung und Test risikofrei ohne Angabe von Zahlungsinformationen möglich',
        'CTA-BTN': 'Jetzt registrieren',
      }
    },
  },
};
